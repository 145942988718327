* {
  font-family: "Pangolin", cursive;
}

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Mochiy+Pop+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');

.light3 {
  min-height: 100vh;
}

.allWrap2 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: max-content;
  background: url('./assets/faqbg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}



@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);

  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.btnfos-0-2:hover {
  border-color: #71b4fc;
}

.wallet2Mobile {
  display: none;
}

.wallet3Mobile {
  display: none;
}

@keyframes pop2 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 499px) {

  .boxWrap2Footer {
    padding-top: 5vh;
    padding-bottom: 4vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 130px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  } 
  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 60px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: #7bb0ff85 2px solid;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .submitBtn{
    font-size: 18px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 2px solid rgb(136, 202, 187);
    padding: 5px;
    width: 100px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 22px;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 15px;
    border: 2px solid #264742;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 20px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .walletC {
    font-size: 16px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }



  .icons {
    display: flex;
    flex-flow: column nowrap;
  }

  .iconsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }


  .navDiv:hover {
    color: #666666;
    }


  .socialIcon img {
    margin-right: 15px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .titleL{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -30px;
  }

  .intro2 {
    color: #505973;
    font-size: 18px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #71b4fc;
    border-radius: 20px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .errorMessage {
    font-size: 18px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 50px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 2px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    background-color: #5e0c6e54;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 12px;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column nowrap;
    display: flex;
    border-bottom: 5px rgb(166, 147, 248) solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }



  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 4vh;

  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px #8284eb;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-radius: 100px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: .75rem 3rem;
      font-size: 1.25rem;
    }
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .nftPic {
    width: 250px;
    /* Adjust as needed */
    height: 250px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 250px;
    /* Adjust as needed */
    width: 250px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .star {
    width: 25%;
    }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-right: 5%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    font-size: 12px;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 18px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 80px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .wand {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -30px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 1px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .boxWrap2Footer {
    padding-top: 5vh;
    padding-bottom: 4vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 130px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  
  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 60px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: #7bb0ff85 2px solid;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .submitBtn{
    font-size: 18px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 2px solid rgb(136, 202, 187);
    padding: 5px;
    width: 100px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 22px;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 15px;
    border: 2px solid #264742;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 20px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .walletC {
    font-size: 16px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
    flex-flow: column nowrap;
  }

  .iconsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }
  .navDiv:hover {
    color: #666666;
    }

  .socialIcon img {
    margin-right: 15px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .titleL{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -50px;
  }
  .intro2 {
    color: #505973;
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 90%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #71b4fc;
    border-radius: 20px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .errorMessage {
    font-size: 18px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 23px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 50px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 2px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #5e0c6e54;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 25px;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgb(166, 147, 248) solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }



  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 4vh;

  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px #8284eb;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-radius: 100px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: .75rem 3rem;
      font-size: 1.25rem;
    }
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .nftPic {
    width: 300px;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 300px;
    /* Adjust as needed */
    width: 300px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .star {
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-right: 5%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
    font-size: 12px;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 18px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 80px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .wand {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;

  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -30px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .boxWrap2Footer {
    padding-top: 7vh;
    padding-bottom: 5vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 150px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .iconsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .icons {
    display: flex;
    flex-flow: column nowrap;
  }

  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 18px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 18px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: 60px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: #7bb0ff85 2px solid;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .submitBtn{
    font-size: 18px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 2px solid rgb(136, 202, 187);
    padding: 5px;
    width: 100px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 25px;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 15px;
    border: 2px solid #264742;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 90%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 22px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .walletC {
    font-size: 17px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }
  .navDiv:hover {
    color: #666666;
    }

  .socialIcon img {
    margin-right: 15px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .intro{
    margin-left: auto;
    margin-right: auto;
  }

  .titleL{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -50px;
  }

  .intro2 {
    color: #505973;
    font-size: 20px;
    text-align: center;
    width: 90%;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #71b4fc;
    border-radius: 20px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .errorMessage {
    font-size: 18px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 50px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }


  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
    background-color: #5e0c6e54;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }


  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    z-index: 1000000000000000000;
    margin-top: 25px;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1a1a1ae8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgb(166, 147, 248) solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }



  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 100;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: none;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 4vh;

  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px #8284eb;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-radius: 100px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: .75rem 3rem;
      font-size: 1.25rem;
    }
  }


  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }

  .totalSupply {
    text-align: center;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .logoUp{
    width: 180px;
    margin-left: 22px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .nftPic {
    width: 300px;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 300px;
    /* Adjust as needed */
    width: 300px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .star {
    width: 20%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
   }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-right: 5%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 25px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 18px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 110px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .wand {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .boxWrap2Footer {
    padding-top: 7vh;
    padding-bottom: 5vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 150px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .miniBarMain {
    display: none;
  }
  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 18px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 18px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-top: 100px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: #7bb0ff85 2px solid;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .submitBtn{
    font-size: 18px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 2px solid rgb(136, 202, 187);
    padding: 5px;
    width: 100px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 25px;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 15px;
    border: 2px solid #264742;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 80%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 22px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .walletC {
    font-size: 17px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }


  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size:18px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 5px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }

  .navDiv:hover {
    color: #202b36;  }

  .socialIcon img {
    margin-right: 15px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    position: relative;
  }

  .star {
    width: 19%;
     margin-left: -5%;
     margin-top: -17%;
    position: absolute;
    z-index: 10;
  }

  .titleL {
    width: 80%;
    position: absolute;
    margin-top: -16%;
  }


  .intro2 {
    color: #505973;
    font-size: 20px;
    text-align: justify;
    width: 90%;

    font-weight: bold;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #71b4fc;
    border-radius: 20px;
    padding: 10px;

  }

  .errorMessage {
    font-size: 18px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 50px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 22px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;   
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 2px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #5e0c6e54;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 8vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px #8284eb;
  }

  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    margin-top: 3%;
    border-radius: 100px;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: .75rem 3rem;
      font-size: 1.25rem;
    }
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .logoUp{
    width: 200px;
    margin-left: 22px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .nftPic {
    width: 300px;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 300px;
    /* Adjust as needed */
    width: 300px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-right: 5%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 80%;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 20px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 150px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .wand {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 70%;

  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


  .boxWrap2Footer {
    padding-top: 7vh;
    padding-bottom: 5vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 150px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }


  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 18px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 18px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 20px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    margin-top: 5%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }


  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: #7bb0ff85 2px solid;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .submitBtn{
    font-size: 18px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 2px solid rgb(136, 202, 187);
    padding: 5px;
    width: 100px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 25px;
  }


  .connectBtn {
    background-color: #309fdf;
    border-radius: 15px;
    border: 2px solid #264742;
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 100;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 50%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 22px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .walletC {
    font-size: 17px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .miniBarMain {
    display: none;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 18px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
     margin-right: 25px;
    font-weight: bolder;
     cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
    margin-top: 4px;
  }

  .navDiv:hover {
    color: #202b36;
  }

  .socialIcon img {
    margin-right: 15px;
    width: 44px;
    height: 44px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    position: relative;
  }

  .star {
    width: 17%;
     margin-left: -5%;
     margin-top: -17%;
    position: absolute;
    z-index: 10;
  }

  .titleL {
    width: 80%;
    position: absolute;
    margin-top: -16%;
  }

  .intro2 {
    color: #505973;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    position: absolute;
    font-weight: bold;
   }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #71b4fc;
    border-radius: 20px;
    padding: 10px;

  }

  .errorMessage {
    font-size: 18px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 60px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 2px solid white;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 2px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 8vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px #8284eb;
    margin-top: 30px;
    position: absolute;
    margin-top: 5%;
    display: none;
  }

  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    margin-top: 5%;
    border-radius: 100px;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: .75rem 3rem;
      font-size: 1.25rem;
    }
  }


  .mintImg{
    width: 15%;
    position: absolute;
    margin-top: 5%;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 95px;
    height: 95px;
    border-radius: 100%;
    border: 2px solid black;
  }
  
  .logoUp{
    width: 200px;
    margin-left: 22px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
   }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
   }

   .nftPic {
    width: 300px;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 300px;
    /* Adjust as needed */
    width: 300px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-right: 5%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 60%;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 20px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 150px;
    border-radius: 100%;
    border: 2px solid black;
  }

  .wand {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 35px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 7px 7px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 1920px) {
  
  .boxWrap2Footer {
    padding-top: 7vh;
    padding-bottom: 5vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 230px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 26px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 24px;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 27px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 18px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 30px;
  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 27px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 18px;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 30px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    margin-top: 9%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 22px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 70px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 15px;
    border-radius: 20px;
    border: #7bb0ff85 4px solid;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .submitBtn{
    font-size: 24px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 4px solid rgb(136, 202, 187);
    padding: 8px;
    width: 150px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 37px;
  }


  .connectBtn {
    background-color: #309fdf;
    border-radius: 20px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    transition: 0.2s ease-in-out;
    font-size: 24px;
    border: 3px solid #264742;

  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 50%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 32px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .walletC {
    font-size: 25px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }


  .miniBarMain {
    display: none;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 24px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    margin-right: 32px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
    margin-top: 6px;
  }

  .navDiv:hover {
    color: #202b36;  }

  .socialIcon img {
    margin-right: 22px;
    width: 65px;
    height: 65px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    position: relative;
  }

  .star {
    width: 17%;
     margin-left: -5%;
     margin-top: -17%;
    position: absolute;
    z-index: 10;
  }

  .titleL {
    width: 80%;
    position: absolute;
    margin-top: -16%;
  }

  .intro2 {
    color: #505973;
    font-size: 30px;
    text-align: justify;
    width: 90%;
    font-weight: bold;
    position: absolute;
   }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 8px solid #71b4fc;
    border-radius: 30px;
    padding: 20px;

  }

  .errorMessage {
    font-size: 26px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 35px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 80px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 28px;
    margin-left: 40px;
    margin-right: 40px;
    width: 70px;
    height: 70px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 4px solid white;
    border-radius: 8px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 350px;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 4px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis{
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 350px;
    margin-bottom: 5%;
    color: white;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 4px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 33px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 100;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 8vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding: 5px;
    padding-left: 60px;
    padding-right: 60px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 7px #8284eb;
    margin-top: 30px;
    position: absolute;
    margin-top: 7%;
  }
  
  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: 2.5rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 450px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    margin-top: 5%;
    border-radius: 100px;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: 1.5rem 4rem;
      font-size: 1.8rem;
    }
  }



  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    border: 4px solid black;
  }

  .logoUp{
    width: 300px;
    margin-left: 30px;
  }


  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
  }

  .nftPic {
    width: 450px;
    /* Adjust as needed */
    height: 450px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 450px;
    /* Adjust as needed */
    width: 450px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }
  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 30px;
    border-radius: 300px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 60%;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
    font-size: 22px;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 28px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 200px;
    border-radius: 100%;
    border: 4px solid black;
  }

  .wand {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 45px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 7px;
    right: 20px;
    cursor: pointer;
    font-size: 35px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  
  .boxWrap2Footer {
    padding-top: 6vh;
    padding-bottom: 4vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 320px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 34px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 32px;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 35px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 40px;
  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 35px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 40px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    margin-top: 10%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 22px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 70px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    border-radius: 30px;
    border: #7bb0ff85 6px solid;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 34px;
  }

  .submitBtn{
    font-size: 34px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 4px solid rgb(136, 202, 187);
    padding: 12px;
    width: 200px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 45px;
  }

  .miniBarMain {
    display: none;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 30px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 18px;
    padding-left: 60px;
    padding-right: 60px;
    transition: 0.2s ease-in-out;
    font-size: 34px;
    border: 4px solid #264742;

  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 50px;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 50%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 42px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .walletC {
    font-size: 32px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }


  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 34px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
    margin-top: 6px;
  }

  .navDiv:hover {
    color: #202b36;  }

  .socialIcon img {
    margin-right: 30px;
    width: 85px;
    height: 85px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    position: relative;
  }

  .star {
    width: 17%;
     margin-left: -5%;
     margin-top: -17%;
    position: absolute;
    z-index: 10;
  }

  .titleL {
    width: 80%;
    position: absolute;
    margin-top: -16%;
  }

  .intro2 {
    color: #505973;
    font-size: 38px;
    text-align: justify;
    width: 90%;
    font-weight: bold;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 10px solid #71b4fc;
    border-radius: 40px;
    padding: 30px;

  }

  .errorMessage {
    font-size: 35px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 42px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 120px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 35px;
    margin-left: 50px;
    margin-right: 50px;
    width: 100px;
    height: 100px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 4px solid white;
    border-radius: 12px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 56px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 480px;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 4px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 56px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 480px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 4px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 45px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 100;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 9vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 52px;
    padding: 10px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 200px;
    box-shadow: 0px 10px #8284eb;
    margin-top: 50px;
    position: absolute;
    margin-top: 5%;
  }

    
  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: 2.5rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 600px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    margin-top: 5%;
    border-radius: 100px;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 8px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: 2.4rem 4rem;
      font-size: 2.5rem;
    }
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 190px;
    height: 190px;
    border-radius: 100%;
    border: 4px solid black;
  }

  .logoUp{
    width: 420px;
    margin-left: 40px;
  }


  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

   .nftPic {
    width: 550px;
    /* Adjust as needed */
    height: 550px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 550px;
    /* Adjust as needed */
    width: 550px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 45px;
    border-radius: 300px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 60%;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
    font-size: 32px;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 39px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 280px;
    border-radius: 100%;
    border: 4px solid black;
  }

  .wand {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #137d85;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #a5209e;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #409e58;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #c48f40;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 55px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 10px 10px #a074e7;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
    font-size: 45px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

@media screen and (min-width: 3840px) {
  
  .boxWrap2Footer {
    padding-top: 6vh;
    padding-bottom: 4vh;
    background-color: #202b36;
    margin-top: 5%;
  }

  .logoF {
    width: 450px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 50px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .txnLink{
    text-align: center;
    color: #baecf0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 45px;
  }
  
  .txnLink:hover{
    color: #78f6ff;
  }
  
  .msg {
    text-align: center;
    color: #3e9e81;
    font-size: 50px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    background-color: #ffffff;
    padding: 30px;
    border-radius: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 50px;
  }

  .msg2 {
    text-align: center;
    color: rgb(223, 92, 92);
    font-size: 50px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-weight: bold;

    background-color: #ffffff;
    padding: 30px;
    border-radius: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop2 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-top: 50px;

  }

  .inputMain {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    margin-top: 9%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 22px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 70px;
  }

  .inputMainSub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .inputMain input {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 30px;
    border-radius: 40px;
    border: #7bb0ff85 10px solid;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 45px;
  }

  .submitBtn{
    font-size: 46px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(158, 241, 188);
    border-radius: 100px;
    border: 7px solid rgb(136, 202, 187);
    padding: 16px;
    width: 280px;
    color: #777575;
   }

  .inputMainSub button {
    background-color: #9394fa;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -5px 5px #8183ee;
    text-align: center;
  }


  .inputT {
    font-size: 60px;
  }

  .miniBarMain {
    display: none;
  }

  .connectBtn {
    background-color: #309fdf;
    border-radius: 40px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 28px;
    padding-left: 80px;
    padding-right: 80px;
    transition: 0.2s ease-in-out;
    font-size: 45px;
    border: 6px solid #264742;
  }

  .connectBtn:active {
    background-color: #146594;
  }

  .connectBtn:hover {
    box-shadow: none;
  }

  .walletDivMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    width: 50%;
    flex-flow: row wrap;
    display: flex;
  }

  .walletSubDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
  }

  .walletT {
    text-align: center;
    font-size: 60px;
    font-family: "Luckiest Guy", cursive;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .walletC {
    font-size: 50px;
    color: rgb(156, 156, 156);
    font-weight: bolder;
  }

  .walletSubDiv img {
    width: 140px;
    height: 140px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }


  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .navDiv {
    font-size: 48px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    margin-right: 50px;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
    margin-top: 10px;

  }

  .navDiv:hover {
    color: #202b36;  }

  .socialIcon img {
    margin-right: 40px;
    width: 125px;
    height: 125px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 60px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro {
    position: relative;
  }

  .star {
    width: 17%;
     margin-left: -5%;
     margin-top: -17%;
    position: absolute;
    z-index: 10;
  }

  .titleL {
    width: 80%;
    position: absolute;
    margin-top: -16%;
  }

  .intro2 {
    color: #505973;
    font-size: 60px;
    text-align: justify;
    width: 90%;
    font-weight: bold;
    position: absolute;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 15px solid #71b4fc;
    border-radius: 50px;
    padding: 50px;

  }

  .errorMessage {
    font-size: 50px;
    color: #e0302a;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadingText {
    font-size: 62px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Pangolin", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Pangolin", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #71b4fc;
    font-size: 160px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: #b78aff;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 50px;
    margin-left: 60px;
    margin-right: 60px;
    width: 140px;
    height: 140px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border: 6px solid white;
    border-radius: 20px;
  }

  .btnfos-0-2:hover:active {
    background-color: #9452fd;
  }

  .btnfos-0-3 {
    font-size: 76px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 20px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 700px;
    margin-bottom: 5%;
    color: white;
    background-color: #8a94ff;
    border: white 6px solid;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3-dis {
    font-size: 76px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 20px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 700px;
    margin-bottom: 5%;
    color: white;
    color: rgba(255, 255, 255, 0.39);
    background-color: #8a94ff52;
    border: rgba(255, 255, 255, 0.596) 6px solid;
    cursor: no-drop;
    margin-top: 5%;
    border-radius: 100px;
  }

  .btnfos-0-3:hover {
    color: rgb(17, 107, 204);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #7d88fd;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 68px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #5e0c6e54;
    z-index: 10;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 9vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: #71b4fc 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 75px;
    padding: 15px;
    padding-left: 150px;
    padding-right: 150px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: #ffffff;
    background: #9394fa;
    border: none;
    border-radius: 400px;
    box-shadow: 0px 15px #8284eb;
    position: absolute;
    margin-top: 5%;
  }

   
  .button-53 {
    background-color: #3DD1E7;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    
    font-size: 2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: 2.5rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 890px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    margin-top: 5%;
    border-radius: 100px;
  }
  
  .button-53:focus {
    outline: 0;
  }
  
  .button-53:after {
    content: '';
    position: absolute;
    border: 1px solid #000000;
    bottom: 12px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    border-radius: 100px;

  }
  
  .button-53:hover:after {
    bottom: 2px;
    left: 2px;
  }
  
  @media (min-width: 768px) {
    .button-53 {
      padding: 4rem 4rem;
      font-size: 3.5rem;
    }
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .logoPic {
    width: 270px;
    height: 270px;
    border-radius: 100%;
    border: 6px solid black;
  }

  .logoUp{
    width: 620px;
    margin-left: 60px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background: url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .light2 {
    background: url('./assets/faqbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
  }

  .nftPic {
    width: 750px;
    /* Adjust as needed */
    height: 750px;
    /* Adjust as needed */
    display: block;
    object-fit: cover;
    /* Ensures the image fills the container */
    z-index: 10;
    /* Ensure the image is above the shape */
    position: absolute;
    /* Position the image within the shape */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shape {
    background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 50%;
    /* Ensure it matches the shape of the image */
    height: 750px;
    /* Adjust as needed */
    width: 750px;
    /* Adjust as needed */
    z-index: 5;
    overflow: hidden;
    /* Ensure the image is clipped to the shape */
    position: relative;
    /* Required for z-index to work */
    animation: morph 5s ease-in-out infinite;
    /* Reapply animation */
  }

  :root {
    --background: #005;
    --primary: #88D5BF;
    --secondary: #5D6BF8;
    --third: #e27fcb;
  }


  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }

    50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
      background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }

    100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
  }

  .flowerDiv {
    text-align: right;
  }

  .flower {
    width: 18%;
    margin-top: -15%;
  }

  .faqMain {
    margin-top: 10vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .section1 {
    display: flex;
    background-color: #99d7fc83;
    padding: 60px;
    border-radius: 400px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 5%;
    width: 60%;
  }

  .section1 div {
    font-family: "Mochiy Pop One", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2%;
    font-size: 42px;
  }

  .section2 {
    font-weight: bold;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-size: 59px;
  }

  .nftFAQ {
    font-family: "Mochiy Pop One", sans-serif;
    width: 440px;
    border-radius: 100%;
    border: 6px solid black;
  }

  .wand {
    width: 140px;
    height: 140px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    /* Start at normal size */
    50% {
      transform: scale(1.1);
    }

    /* Zoom in by 20% */
    100% {
      transform: scale(1);
    }

    /* Return to normal size */
  }

  .popUpMain {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #33a88b;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #07aab6;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d849d1;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup4 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6ed387;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup5 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fdba55;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .popup6 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c6a3ff;
    padding: 65px;
    padding-bottom: 0;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    box-shadow: 15px 15px #1f7e66;
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }


  @keyframes pop {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .popup.active {
    display: block;
  }

  .flowerDiv {
    text-align: right;
  }

  #popup img {
    width: 20%;
    margin-top: -60px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 40px;
    cursor: pointer;
    font-size: 55px;
    z-index: 50;
  }

  .content {
    height: 2000px;
    
  }
}

.logo img:hover{
  cursor: pointer;
}